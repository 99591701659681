import React, { useState, useEffect } from 'react';
import axios from 'axios';

const API_URL = 'https://dev.amm.limitless.exchange/api';

export default function App() {
  const [markets, setMarkets] = useState({});
  const [selectedMarket, setSelectedMarket] = useState(null);
  const [marketState, setMarketState] = useState(null);
  const [newMarketParams, setNewMarketParams] = useState({
    id: '',
    outcomes: '',
    initialPrices: '',
    initialLiquidity: '',
    lowerBound: '',
    upperBound: ''
  });
  const [liquidityParams, setLiquidityParams] = useState({
    amount: 0,
    lower: 0,
    upper: 1,
    address: '',
  });
  const [tradeParams, setTradeParams] = useState({
    outcome: '',
    amount: 0,
    address: '',
  });
  const [actionHistory, setActionHistory] = useState([]);

  useEffect(() => {
    fetchMarkets();
  }, []);

  const fetchMarkets = async () => {
    try {
      const response = await axios.get(`${API_URL}/markets`);
      setMarkets(response.data);
    } catch (error) {
      console.error("Err or fetching markets:", error);
    }
  };

  const fetchMarketState = async (marketId) => {
    try {
      const response = await axios.get(`${API_URL}/market-state/${marketId}`);
      setMarketState(response.data);
    } catch (error) {
      console.error("Error fetching market state:", error);
    }
  };

  const handleCreateMarket = async () => {
    try {
      await axios.post(`${API_URL}/create-market`, {
        id: newMarketParams.id,
        outcomes: newMarketParams.outcomes.split(','),
        initial_prices: Object.fromEntries(
          newMarketParams.initialPrices.split(',').map((price, index) => [
            newMarketParams.outcomes.split(',')[index],
            parseFloat(price),
          ])
        ),
        initial_liquidity: parseFloat(newMarketParams.initialLiquidity) || 0,
        lower_bound: parseFloat(newMarketParams.lowerBound) || 0,
        upper_bound: parseFloat(newMarketParams.upperBound) || 1,
      });
      fetchMarkets();
      addToHistory('Created market', newMarketParams);
      setNewMarketParams({ id: '', outcomes: '', initialPrices: '', initialLiquidity: '', lowerBound: '', upperBound: '' });
    } catch (error) {
      console.error("Error creating market:", error);
    }
  };

  const handleAddLiquidity = async () => {
    try {
      const response = await axios.post(`${API_URL}/add-liquidity`, {
        market_id: selectedMarket,
        amount: parseFloat(liquidityParams.amount) || 0,
        lower: parseFloat(liquidityParams.lower) || 0,
        upper: parseFloat(liquidityParams.upper) || 1,
        address: liquidityParams.address,
      });
      setMarketState(response.data.state);
      addToHistory('Added liquidity', liquidityParams);
      setLiquidityParams({ amount: 0, lower: 0, upper: 1, address: '' });
    } catch (error) {
      console.error("Error adding liquidity:", error);
    }
  };

  const handleTrade = async () => {
    try {
        const response = await axios.post(`${API_URL}/trade`, {
            market_id: selectedMarket,
            outcome: tradeParams.outcome,
            amount: parseFloat(tradeParams.amount) || 0,
            address: tradeParams.address,
        });
        console.log('Trade response:', response.data);
        setMarketState(response.data.state);
        addToHistory('Executed trade', tradeParams);
        setTradeParams({ outcome: '', amount: 0, address: '' });
    } catch (error) {
        console.error("Error executing trade:", error);
    }
  };

  const addToHistory = (action, params) => {
    setActionHistory((prev) => [{ action, params, timestamp: new Date().toISOString() }, ...prev]);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4">Prediction Markets</h1>
      
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div>
          <h2 className="text-xl font-semibold mb-2">Create New Market</h2>
          <input
            type="text"
            placeholder="Market ID"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.id}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, id: e.target.value })}
          />
          <input
            type="text"
            placeholder="Outcomes (comma-separated)"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.outcomes}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, outcomes: e.target.value })}
          />
          <input
            type="text"
            placeholder="Initial Prices (comma-separated)"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.initialPrices}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, initialPrices: e.target.value })}
          />
          <input
            type="number"
            placeholder="Initial Liquidity"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.initialLiquidity || ''}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, initialLiquidity: e.target.value })}
          />
          <input
            type="number"
            placeholder="Lower Bound"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.lowerBound || ''}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, lowerBound: e.target.value })}
          />
          <input
            type="number"
            placeholder="Upper Bound"
            className="border p-2 mb-2 w-full"
            value={newMarketParams.upperBound || ''}
            onChange={(e) => setNewMarketParams({ ...newMarketParams, upperBound: e.target.value })}
          />
          <button
            className="bg-blue-500 text-white p-2 rounded"
            onClick={handleCreateMarket}
          >
            Create Market
          </button>
        </div>
        
        <div>
          <h2 className="text-xl font-semibold mb-2">Select Market</h2>
          <select
            className="border p-2 mb-2 w-full"
            value={selectedMarket || ''}
            onChange={(e) => {
              setSelectedMarket(e.target.value);
              if (e.target.value) fetchMarketState(e.target.value);
            }}
          >
            <option value="">Select a market</option>
            {Object.keys(markets).map((marketId) => (
              <option key={marketId} value={marketId}>{marketId}</option>
            ))}
          </select>
        </div>
      </div>

      {selectedMarket && (
        <div className="grid grid-cols-4 gap-4">
          <div className='col-span-1'>
            <h2 className="text-xl font-semibold mb-2">Add Liquidity</h2>
            <input
              type="number"
              placeholder="Amount"
              className="border p-2 mb-2 w-full"
              value={liquidityParams.amount || ''}
              onChange={(e) => setLiquidityParams({ ...liquidityParams, amount: e.target.value })}
            />
            <input
              type="number"
              placeholder="Lower Bound"
              className="border p-2 mb-2 w-full"
              value={liquidityParams.lower || ''}
              onChange={(e) => setLiquidityParams({ ...liquidityParams, lower: e.target.value })}
            />
            <input
              type="number"
              placeholder="Upper Bound"
              className="border p-2 mb-2 w-full"
              value={liquidityParams.upper || ''}
              onChange={(e) => setLiquidityParams({ ...liquidityParams, upper: e.target.value })}
            />
            <input
              type="text"
              placeholder="Address"
              className="border p-2 mb-2 w-full"
              value={liquidityParams.address}
              onChange={(e) => setLiquidityParams({ ...liquidityParams, address: e.target.value })}
            />
            <button
              className="bg-blue-500 text-white p-2 rounded"
              onClick={handleAddLiquidity}
            >
              Add Liquidity
            </button>
          </div>
          
          <div>
            <h2 className="text-xl font-semibold mb-2">Trade</h2>
            <select
              className="border p-2 mb-2 w-full"
              value={tradeParams.outcome}
              onChange={(e) => setTradeParams({ ...tradeParams, outcome: e.target.value })}
            >
              <option value="">Select an outcome</option>
              {markets[selectedMarket]?.outcomes.map((outcome) => (
                <option key={outcome} value={outcome}>{outcome}</option>
              ))}
            </select>
            <input
              type="number"
              placeholder="Amount"
              className="border p-2 mb-2 w-full"
              value={tradeParams.amount || ''}
              onChange={(e) => setTradeParams({ ...tradeParams, amount: e.target.value })}
            />
            <input
              type="text"
              placeholder="Address"
              className="border p-2 mb-2 w-full"
              value={tradeParams.address}
              onChange={(e) => setTradeParams({ ...tradeParams, address: e.target.value })}
            />
            <button
              className="bg-green-500 text-white p-2 rounded"
              onClick={handleTrade}
            >
              Execute Trade
            </button>
          </div>
        </div>
      )}

      {marketState && (
          <div className="mt-10">
              <h2 className="text-xl font-semibold mb-2">Market State</h2>
              <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-1">
                      {/* <h3 className="text-lg font-semibold mb-2">Prices</h3> */}
                      <ul>
                          {Object.entries(marketState.prices).map(([outcome, price]) => (
                              <li key={outcome}>{outcome}: {price.toFixed(4)}</li>
                          ))}
                      </ul>
                      <p className="mt-2">Total Liquidity: {marketState.total_liquidity.toFixed(2)} ETH</p>
                  </div>
                  <div className="col-span-3">
                      {/* <h3 className="text-lg font-semibold mb-2">Market Visualization</h3> */}
                      {marketState.plot && (
                          <img src={`data:image/png;base64,${marketState.plot}`} alt="Market Visualization" className="w-full" />
                      )}
                  </div>
              </div>
          </div>
      )}


      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-2">Action History</h2>
        <ul className="divide-y divide-gray-200">
          {actionHistory.map((action, index) => (
            <li key={index} className="py-2">
              <p className="font-semibold">{action.action}</p>
              <p className="text-sm text-gray-500">{action.timestamp}</p>
              <pre className="mt-1 text-sm">{JSON.stringify(action.params, null, 2)}</pre>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
